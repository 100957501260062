import { i18n } from "~/i18n.config";
import { cleanPathname } from "~/i18n";

interface Props {
  pathname: string;
}

function canonicalUrl(pathname: string, locale: string) {
  return `/${locale}${cleanPathname(pathname)}`;
}

export function AlternateHrefLang({ pathname }: Props) {
  return (
    <>
      <link
        rel="alternate"
        href={canonicalUrl(pathname, i18n.defaultLocale)}
        hrefLang="x-default"
      />
      {i18n.locales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          href={canonicalUrl(pathname, locale)}
          hrefLang={locale}
        />
      ))}
    </>
  );
}
