export default function SocialIcons(): JSX.Element {
  return (
    <>
      <a href="https://discord.gg/rlsideswipe/" className="mx-1">
        <img
          src="/images/icons/icon-discord.svg"
          width="37.5px"
          height="37.5px"
          alt="Discord"
        />
      </a>

      <a href="https://www.reddit.com/r/RLSideSwipe/" className="mx-1">
        <img
          src="/images/icons/icon-reddit.svg"
          width="37.5px"
          height="37.5px"
          alt="Reddit"
        />
      </a>

      <a href="https://www.twitch.tv/rocketleague/" className="mx-1">
        <img
          src="/images/icons/icon-twitch.svg"
          width="37.5px"
          height="37.5px"
          alt="Twitch"
        />
      </a>

      <a href="http://www.instagram.com/RLSideswipe" className="mx-1">
        <img
          src="/images/icons/icon-instagram.svg"
          width="37.5px"
          height="37.5px"
          alt="Instagram"
        />
      </a>

      <a href="http://www.facebook.com/RLSideswipe" className="mx-1">
        <img
          src="/images/icons/icon-facebook.svg"
          width="37.5px"
          height="37.5px"
          alt="Facebook"
        />
      </a>

      <a href="https://twitter.com/RLSideswipe/" className="mx-1">
        <img
          src="/images/icons/icon-x.svg"
          width="37.5px"
          height="37.5px"
          alt="X"
        />
      </a>

      <a href="https://www.youtube.com/user/RocketLeagueGame" className="mx-1">
        <img
          src="/images/icons/icon-youtube.svg"
          width="37.5px"
          height="37.5px"
          alt="YouTube"
        />
      </a>
    </>
  );
}
