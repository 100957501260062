export default function OneTrustBanner(): React.JSX.Element {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `
                  <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="2ef61c1a-8f73-4d7e-92a3-0c9254b658db" ></script>
                  <script type="text/javascript">
                  function OptanonWrapper() { }
                  </script>
                  `,
        }}
      />
    </>
  );
}
